import { CookieService } from 'ngx-cookie-service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { UserService } from '../../services/user.service';
import { QueryService } from '../../services/query.service';
import { FormBuilder, Validators } from '@angular/forms';
import { IChatHistory, IQuery } from '../../shared/interfaces/query.interface';
import { faCopy, faHashtag, faTag } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { PromptService } from '../../services/prompt.service';
import { IPrompt } from '../../shared/interfaces/prompt.interface';

@Component({
    selector: 'app-redirect',
    templateUrl: './redirect.component.html',
    styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit, OnDestroy {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
    ) {
    }
    ngOnInit(): void {
        const accessToken = this.route.snapshot.queryParams['access_token'];
        // if (accessToken) {
        //     this.userService.login("","", accessToken).subscribe((res) => {
        //         localStorage.setItem('token', res?.token)
        //         this.userService.verify().subscribe(
        //             response => {
        //                 console.log(response);
        //                 this.router.navigate(['/home']);
        //             },
        //             error => {
        //                 console.log(error);
        //                 this.router.navigate(['/unavailable']);
        //             }
        //         )  
        //     })
        // } else {
        //     this.userService.verify().subscribe(
        //         response => {
        //             console.log(response);
        //             this.router.navigate(['/home']);
        //         },
        //         error => {
        //             console.log(error);
        //             this.router.navigate(['/unavailable']);
        //         }
        //     )            
        // }

    }
    ngOnDestroy() {
    }

}